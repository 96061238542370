import {
  Characteristic,
  IActivable,
  ICharacteristic,
  IOrganizationUnit,
  IPopulationLevel,
  OrganizationUnit,
  PopulationLevel,
} from '.';

export interface ICityHall extends IOrganizationUnit, IActivable {
  id: string;
  name: string;
  website: string;
  postalCode: string;
  cif: string;
  isActive: boolean;
  characteristic: ICharacteristic | undefined;
  populationLevel: IPopulationLevel | undefined;
}

export class CityHall extends OrganizationUnit implements ICityHall {
  isActive: boolean;
  cif: string;
  website: string;
  postalCode: string;
  characteristic: ICharacteristic | undefined;
  populationLevel: PopulationLevel | undefined;

  constructor(data: ICityHall) {
    super(data);
    this.cif = data.cif;
    this.name = data.name;
    this.website = data.website;
    this.postalCode = data.postalCode;
    this.isActive = data.isActive;
    this.characteristic = data.characteristic
      ? new Characteristic(data.characteristic)
      : undefined;
    this.populationLevel = data.populationLevel
      ? new PopulationLevel(data.populationLevel)
      : undefined;
  }
}
