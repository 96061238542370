import { Characteristic } from './characteristic';
import { Indicator } from './indicator';
import { InvoiceLevel } from './invoice-level';
import { PopulationLevel } from './population-level';
import { Sector } from './sector';
import {Company} from "./company";
import {CityHall} from "./cityhall";
import {Community} from "./community";
import {OrganizationUnit} from "./organization-unit";

export interface ITarget {
  organizationUnits: Array<any>; // temporal
  sectors: Array<Sector>;
  invoiceLevels: Array<InvoiceLevel>;
  populationLevels: Array<PopulationLevel>;
  characteristics: Array<Characteristic>;
  indicators: Array<Indicator>;
}

export class Target implements ITarget {
  organizationUnits: Array<OrganizationUnit>;
  sectors: Array<Sector>;
  invoiceLevels: Array<InvoiceLevel>;
  populationLevels: Array<PopulationLevel>;
  characteristics: Array<Characteristic>;
  indicators: Array<Indicator>;

  constructor(data: ITarget) {
    this.organizationUnits = data.organizationUnits.map((el) => {
      let item: OrganizationUnit = new OrganizationUnit(el);
      if(el.type == 'company') { item = new Company(el); }
      if(el.type == 'cityhall') { item = new CityHall(el); }
      if(el.type == 'community') { item = new Community(el); }
      return item;
    })
    this.sectors = data.sectors.map((el) => new Sector(el));
    this.invoiceLevels = data.invoiceLevels.map((el) => new InvoiceLevel(el));
    this.populationLevels = data.populationLevels.map(
      (el) => new PopulationLevel(el)
    );
    this.characteristics = data.characteristics.map(
      (el) => new Characteristic(el)
    );
    this.indicators = data.indicators.map((el) => new Indicator(el));
  }
}
