import {Entity, IActivable, IEntity, ValidityPeriod} from ".";
import { ILocaleField } from "./locale-field";

export interface IContentType extends IEntity {
  name: string;
  nameKey?: string;
}

export interface IContent extends IEntity, IActivable {
  name: string;
  description: string;
  hasTarget: boolean;
  isActive: boolean;
  localeName: ILocaleField[];
  localeDescription: ILocaleField[];
  validityPeriod: ValidityPeriod | null;
  contentType: IContentType;
}

export class ContentType extends Entity implements IContentType {
  name: string;
  get nameKey(): string {
    return `contentType.${ this.name }`;
  }

  constructor(data: IContentType) {
    super(data);
    this.name = data.name;
  }
}

export class Content extends Entity implements IContent {
  name: string;
  description: string;
  hasTarget: boolean;
  isActive: boolean;
  contentType: ContentType;
  localeName: ILocaleField[];
  localeDescription: ILocaleField[];
  validityPeriod: ValidityPeriod | null;

  constructor(data: IContent) {
    super(data);
    this.name = data.name;
    this.description = data.description;
    this.hasTarget = data.hasTarget;
    this.isActive = data.isActive;
    this.isActive = data.isActive;
    this.contentType = new ContentType(data.contentType);
    this.hasTarget = data.hasTarget;
    this.localeName = data.localeName;
    this.localeDescription = data.localeDescription;
    this.validityPeriod = data.validityPeriod
      ? new ValidityPeriod(data.validityPeriod)
      : null;
  }
}
