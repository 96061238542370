import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { IUser } from '../models';
import {
  ICreateCompanyCommand,
  IUpdateCompanyCommand,
} from '../models/commands/company-command';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/users`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<Array<IUser>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<Array<IUser>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<IUser> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IUser>(url);
  }

  create(sector: ICreateCompanyCommand): Observable<IUser> {
    return this.post<IUser>(this.url, sector);
  }

  update(id: string, sector: IUpdateCompanyCommand): Observable<IUser> {
    return this.put<IUser>(`${this.url}/${id}`, sector);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
