export const ActionState = {
  Activated: 'actionStates.activated',
  Deactivated: 'actionStates.deactivated',
  Deleted: 'actionStates.deleted',
  Updated: 'actionStates.updated',
  Created: 'actionStates.created',
  Uploaded: 'actionStates.uploaded',
  Error: 'actionStates.error',
  Successfully: 'actionStates.successfully',
  ErrorDeleted: 'actionStates.errorDeleted',
  Sended: 'actionStates.sended',
  Downloaded: 'actionStates.downloaded',
  Subscribed: 'actionStates.subscribed',
} as const;

export type ActionStateKey = (typeof ActionState)[keyof typeof ActionState];
export const actionStatesKeys: ActionStateKey[] = Object.values(ActionState);
