import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import {Observable, switchMap} from 'rxjs';
import {Activity, EntityList, IEntityList} from '../models';
import { IActivityType, IActivity } from '../models';
import {map} from "rxjs/operators";
import {ISubscribeToActivityCommand} from "../models/commands/activity-commands";
import {IResourceFile} from "../models/resource-file";
import { UserActivityInscription } from '../models/user-activity-inscription';

@Injectable({
  providedIn: 'root',
})
export class ActivityService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/activities`;

  getAll(langCode: string, filters: string | null = null): Observable<EntityList<Activity>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<EntityList<Activity>>(url).pipe(
      map((entityList) => new EntityList({
        listObject: entityList.listObject.map(item => new Activity(item)),
        totalRows: entityList.totalRows
      } as IEntityList<Activity>))
    );
  }

  getMy(langCode: string, filters: string | null = null): Observable<EntityList<Activity>> {
    const url = `${this.url}/me?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<Activity>>(url).pipe(
      map(entityList => new EntityList({
        listObject: entityList.listObject.map(item => new Activity(item)),
        totalRows: entityList.totalRows
      } as IEntityList<Activity>))
    );
  }

  getUserInscriptions(langCode: string, filters: string | null = null): Observable<EntityList<UserActivityInscription>> {
    const url = `${this.url}/inscriptions?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<EntityList<UserActivityInscription>>(url).pipe(
      map((entityList) => new EntityList({
        listObject: entityList.listObject.map(item => new UserActivityInscription(item)),
        totalRows: entityList.totalRows
      } as IEntityList<UserActivityInscription>))
    );
  }

  subscribe(id: string, command: ISubscribeToActivityCommand, langCode: string): Observable<Activity> {
    const url = `${this.url}/${id}/subscribe?languageCode=${langCode}`;
    return this.post<Activity>(url, command).pipe(
      map(activity => new Activity(activity))
    );
  }

  getTypes(langCode: string, filters: string | null = null)
    : Observable<IEntityList<IActivityType>> {
    const url = `${this.url}/types?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IActivityType>>(url);
  }

  create(activity: any): Observable<Activity> {
    return this.post<Activity>(this.url, activity).pipe(
      map((data) => new Activity(data))
    );
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }

  getById(id: string, langCode: string): Observable<IActivity> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IActivity>(url);
  }

  update(id: string, communityType: any): Observable<IActivity> {
    return this.put<IActivity>(`${this.url}/${id}`, communityType);
  }

  uploadFile(activityId: string, file: File): Observable<IResourceFile> {
    return this.getHeadersFile().pipe(
      switchMap((headers) => {
        const formData = new FormData();
        formData.append('file', file);

        return this.post<IResourceFile>(
          `${this.url}/${activityId}/file`,
          formData,
          headers
        );
      })
    );
  }

}
