import { Injectable, Signal } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import {IEntityList, IOrganizationUnit, OrganizationUnit} from '../models';
import { ApiService } from '.';
import { toSignal } from '@angular/core/rxjs-interop';
import { IOrganizationUnitType, OrganizationUnitType } from '../models/organization-unit-type';

@Injectable({
  providedIn: 'root',
})
export class OrganizationUnitService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/organization-units`;

  public organization: Signal<OrganizationUnit | undefined> = toSignal(
    this.getByUser()
  );

  getByUser(): Observable<IOrganizationUnit> {
    return this.get<IOrganizationUnit>(`${this.url}/me`);
  }

  getTypes(): Observable<OrganizationUnitType[]> {
    return this.get<Array<IOrganizationUnitType>>(`${this.url}/types`).pipe(
      map((types) => types.map((type) => new OrganizationUnitType(type)))
    );
  }

  getAllByType(
    type: string | null = null,
    filters: string | null = null
  ): Observable<IEntityList<OrganizationUnit>> {
    const params = new URLSearchParams();
    if (type) {
      params.set('type', type);
    }
    if (filters) {
      params.set('filterBase64', filters);
    }

    const url = `${this.url}${params.toString() ? '?' + params.toString() : ''}`;
    return this.get<IEntityList<OrganizationUnit>>(url);
  }
}
