import { Entity, IActivable, IEntity } from '.';
import { ILocaleField } from './locale-field';

export interface ISector extends IEntity, IActivable {
  name: string;
  cnae: number;
  localeName: ILocaleField[];
}

export class Sector extends Entity implements ISector {
  name: string;
  cnae: number;
  isActive: boolean;
  localeName: ILocaleField[] = [];

  constructor(data: ISector) {
    super(data);
    this.name = data.name;
    this.cnae = data.cnae;
    this.isActive = data.isActive;
    this.localeName = data.localeName;
  }
}
