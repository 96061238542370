import { computed, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationUnitService } from './organization-unit.service';
import { LanguagePreferencesService } from './language-preferences.service';
import { AuthService } from '../../auth/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  organizationLangCode = computed(() => {
    return this.organizationUnitService.organization()?.language!.code;
  });

  currentLang = signal<string>('');
  langChanged = signal<boolean>(false);
  init: boolean = true;

  availableLanguages = signal<string[]>(['es', 'gl']);

  constructor(
    private organizationUnitService: OrganizationUnitService,
    private languagePreferences: LanguagePreferencesService,
    private translate: TranslateService,
    private authService: AuthService,
  ) {
    this.initialize();
  }

  private async initialize() {
    const manualLang = this.languagePreferences.getManualLangChange();
    if (manualLang) {
      this.setLanguage(manualLang, false);
    } else {
      const userLocaleSet = await this.setUserLocaleLanguage();
      if (!userLocaleSet) {
        this.setOrgnaizationLanguage();
      }
    }
    this.init = false;
  }

  private setOrgnaizationLanguage() {
    const orgLang = this.organizationLangCode();
    if (orgLang) {
      this.setLanguage(orgLang, false);
    }
  }

  private async setUserLocaleLanguage(): Promise<boolean> {
    const user = await this.authService.loadUserProfile();
    const locale = user?.attributes?.['locale'] as string | undefined;
    if (locale) {
      this.setLanguage(locale[0], false);
      return true;
    }
    return false;
  }

  setLanguage(langCode: string, manualChange: boolean) {
    if (this.currentLang() !== langCode) {
      this.translate.use(langCode);
      this.currentLang.set(langCode);
      this.langChanged.set(true);

      if (manualChange) {
        this.languagePreferences.setManualLangChange(langCode);
      }
    }
  }
}
