import {
  Entity,
  IActivable,
  IEntity,
  IInvoiceLevel,
  InvoiceLevel,
  IOrganizationUnit,
  ISector,
  OrganizationUnit,
  Sector,
} from '.';


export interface ICompanyScope extends IEntity {
  name: string;
  nameKey?: string;
}

export interface ICompanySize extends IEntity {
  name: string;
  nameKey?: string;
}

export interface ICompanyLegalStatus extends IEntity {
  name: string;
  nameKey?: string;
}

export interface ICompany extends IOrganizationUnit, IActivable {
  website: string;
  cif: string;
  legalStatus: ICompanyLegalStatus;
  postalCode: string;
  scope: ICompanyScope;
  size: ICompanySize;
  sector: ISector | undefined;
  invoiceLevel: IInvoiceLevel | undefined;
}

export class CompanyScope extends Entity implements ICompanyScope {
  name: string;
  get nameKey(): string {
    return `scope.types.${this.name}`;
  }

  constructor(data: ICompanyScope) {
    super(data);
    this.name = data.name;
  }
}

export class CompanyLegalStatus extends Entity implements ICompanyLegalStatus {
  name: string;
  get nameKey(): string {
    return `legalStatus.types.${this.name}`;
  }

  constructor(data: ICompanyLegalStatus) {
    super(data);
    this.name = data.name;
  }
}

export class CompanySize extends Entity implements ICompanySize {
  name: string;
  get nameKey(): string {
    return `size.types.${this.name}`;
  }

  constructor(data: ICompanySize) {
    super(data);
    this.name = data.name;
  }
}

export class Company extends OrganizationUnit implements ICompany {
  sector: Sector | undefined;
  invoiceLevel: InvoiceLevel | undefined;
  isActive: boolean;
  website: string;
  cif: string;
  legalStatus: CompanyLegalStatus;
  postalCode: string;
  scope: CompanyScope;
  size: CompanySize;

  constructor(data: ICompany) {
    super(data);
    this.sector = data.sector ? new Sector(data.sector) : undefined;
    this.invoiceLevel = data.invoiceLevel
      ? new InvoiceLevel(data.invoiceLevel)
      : undefined;
    this.isActive = data.isActive;
    this.website = data.website;
    this.cif = data.cif;
    this.legalStatus = new CompanyLegalStatus(data.legalStatus);
    this.postalCode = data.postalCode;
    this.scope = new CompanyScope(data.scope);
    this.size = new CompanySize(data.size);
  }
}
