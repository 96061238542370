import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IEntityList } from '../models';
import { IIndicator } from '../models/indicator';
import {
  ICreateIndicatorCommand,
  IUpdateIndicatorCommand,
} from '../models/commands/indicator-commands';

@Injectable({
  providedIn: 'root',
})
export class IndicatorService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/indicators`;

  getById(id: string, langCode: string): Observable<IIndicator> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<IIndicator>(url);
  }

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<IIndicator>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<IIndicator>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  create(indicator: ICreateIndicatorCommand): Observable<IIndicator> {
    return this.post<IIndicator>(this.url, indicator);
  }

  update(
    id: string,
    indicator: IUpdateIndicatorCommand
  ): Observable<IIndicator> {
    return this.put<IIndicator>(`${this.url}/${id}`, indicator);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
