import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { IEntityList, ISector } from '../models';
import { ApiService } from '.';
import {
  ICreateSectorCommand,
  IUpdateSectorCommand,
} from '../models/commands/sector-command';

@Injectable({
  providedIn: 'root',
})
export class SectorService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/sectors`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<ISector>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<ISector>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<ISector> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<ISector>(url);
  }

  create(sector: ICreateSectorCommand): Observable<ISector> {
    return this.post<ISector>(this.url, sector);
  }

  update(id: string, sector: IUpdateSectorCommand): Observable<ISector> {
    return this.put<ISector>(`${this.url}/${id}`, sector);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
