import { Injectable } from '@angular/core';
import { ApiService } from './api-service.service';
import { environment } from '../../../environments/environment';
import { ICityHall, IEntityList } from '../models';
import { Observable } from 'rxjs';
import {
  ICreateCityhallCommand,
  IUpdateCityhallCommand,
} from '../models/commands/cityhall-command';

@Injectable({
  providedIn: 'root',
})
export class CityHallService extends ApiService {
  private readonly url = `${environment.api.baseUrl}/city-halls`;

  getAll(
    langCode: string,
    filters: string | null = null
  ): Observable<IEntityList<ICityHall>> {
    const url = `${this.url}?languageCode=${langCode}${filters ? `&filterBase64=${filters}` : ''}`;
    return this.get<IEntityList<ICityHall>>(url);
  }

  toggleIsActive(
    id: string,
    command: { isActive: boolean }
  ): Observable<{ isActive: boolean }> {
    return this.patch<{ isActive: boolean }>(`${this.url}/${id}`, command);
  }

  getById(id: string, langCode: string): Observable<ICityHall> {
    const url = `${this.url}/${id}?languageCode=${langCode}`;
    return this.get<ICityHall>(url);
  }

  create(sector: ICreateCityhallCommand): Observable<ICityHall> {
    return this.post<ICityHall>(this.url, sector);
  }

  update(id: string, sector: IUpdateCityhallCommand): Observable<ICityHall> {
    return this.put<ICityHall>(`${this.url}/${id}`, sector);
  }

  remove(id: string): Observable<boolean> {
    return this.delete(`${this.url}/${id}`);
  }
}
